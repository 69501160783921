import React from "react";
import HubspotForm from "react-hubspot-form";

function EmailSignUpForm() {
	return (
		<>
			<p className="text text--xsmall text--orange">Join the Irish Titan sphere of influence. We'll send a couple emails a month. No trash, no spam, just the shenanigans we get up to.</p>
			<HubspotForm
				portalId="544161"
				formId="90cb3711-0007-4af0-aa83-fec5bc9e774b"
				region="na1"
				css=""
				submitButtonClass="btn btn--black-green btn--sm"
			/>
		</>
	);
}

export default EmailSignUpForm;
