import React from "react";
import { graphql, Link } from "gatsby";
import { Helmet } from "react-helmet";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";
import SearchEngineOptimization from "../../components/SearchEngineOptimization";
import ArticleCard from "../../components/ArticleCard";
import ArticleCardSmall from "../../components/ArticleCardSmall";
import EmailSignUpForm from "../../components/Forms/EmailSignUpForm";
import "./styles.scss";
import PolygonOrange1 from "../../images/polygons/polygon-orange-1.svg";
import PolygonOrange2 from "../../images/polygons/polygon-orange-2.svg";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import BREAKPOINTS from "../../utils/breakpoints";

function ArticlePage({
	data: { siteData, article, relatedArticles, recentArticles },
}) {
	const {
		title,
		category,
		publishedDate,
		heroImage,
		body,
		excerpt: { excerpt },
	} = article;

	const heroImageData = getImage(heroImage);

	const isDesktop = useMediaQuery(`(min-width: ${BREAKPOINTS.LARGE.VALUE}px)`);

	return (
		<Layout>
			<SearchEngineOptimization title={`${title}`} description={excerpt} />
			<Helmet>
				<script type="application/ld+json">
					{`{
						"@context" : "http://schema.org",
						"@type" : "BlogPosting",
						"title" : "${title}",
						"url" : "/news/${category.slug}",
						"description": "${excerpt}",
						"authorName" : "${siteData.author}",
						"datePublished" : "${publishedDate}",
						"images" : "["${heroImageData?.images?.fallback?.src}"]"
					}`}
				</script>
			</Helmet>
			<div className="section">
				<div className="container">
					<div className="article">
						<div className="article__hero">
							{heroImageData && (
								<GatsbyImage
									image={heroImageData}
									alt={
										heroImage.description
											? heroImage.description
											: heroImage.title
									}
								/>
							)}
							<Link to={`/news/${category.slug}`} className="article__type">
								{category.title}
							</Link>
						</div>
						<div className="article__content-container">
							<div className="article__content">
								<h1 className="article__heading">{title}</h1>
								<time dateTime={publishedDate}>{publishedDate}</time>
								{body && (
									<div
										className="article__body markdown"
										dangerouslySetInnerHTML={{
											__html: body.childMarkdownRemark.html,
										}}
									/>
								)}
								<div className="polygon">
									<img
										className="polygon__image"
										src={PolygonOrange2}
										alt=""
										aria-hidden="true"
									/>
								</div>
							</div>
							<div className="article__more-reads">
								{isDesktop && <div className="article__subscribe">
									<div className="polygon">
										<img
											className="polygon__image"
											src={PolygonOrange1}
											alt=""
											aria-hidden="true"
										/>
									</div>
									<h2 className="text text--large text--weight-bold">
										Trust us, you’re gonna want this in your inbox.
									</h2>
									<EmailSignUpForm />
								</div>}
								<div className="article__more-reads">
									<h2 className="text text--small text--weight-bold">
										More reads
									</h2>
									<div className="article__more-reads-cards">
										{recentArticles.edges.map(article => {
											const { title, heroImage, previewImage, category, slug } = article.node;
											return (
												<ArticleCardSmall
													key={title}
													previewImage={previewImage}
													heroImage={heroImage}
													category={category.title}
													title={title}
													slug={slug}
												/>
											);
										})}
									</div>
								</div>
							</div>

							{relatedArticles.edges.length > 0 && (
								<div className="article__even-more-reads">
									<div className="text text--small text--orange text--uppercase text--weight-semi-bold">
										Go forth and read
									</div>
									<h2 className="text text--large text--weight-bold">
										More from {category.title}...
									</h2>
									<div className="article__even-more-reads-cards">
										{relatedArticles.edges.map(article => {
											const {
												title,
												previewImage,
												heroImage,
												category,
												slug,
												excerpt: { excerpt },
											} = article.node;
											return (
												<ArticleCard
													key={title}
													previewImage={previewImage}
													heroImage={heroImage}
													category={category.title}
													title={title}
													excerpt={excerpt}
													slug={slug}
												/>
											);
										})}
									</div>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export const query = graphql`
	query($slug: String!, $categoryTitle: String!) {
		siteData: site {
			siteMetadata {
				author
			}
		}
		article: contentfulArticle(slug: { eq: $slug }) {
			title
			publishedDate(formatString: "MMMM DD, YYYY")
			excerpt {
				excerpt
			}
			category {
				title
				slug
			}
			body {
				childMarkdownRemark {
					html
				}
			}
			heroImage {
				title
				description
				gatsbyImageData(
					layout: FULL_WIDTH
					placeholder: DOMINANT_COLOR
					formats: [AUTO, WEBP]
					quality: 100
				)
			}
		}
		relatedArticles: allContentfulArticle(
			filter: {
				category: { title: { eq: $categoryTitle } }
				slug: { ne: $slug }
			}
			limit: 3
			sort: { fields: publishedDate, order: DESC }
		) {
			edges {
				node {
					title
					previewImage {
						gatsbyImageData(
							placeholder: NONE
							formats: [AUTO, WEBP]
							aspectRatio: 1.64
						)
						description
						title
					}
					heroImage {
						gatsbyImageData(
							placeholder: NONE
							formats: [AUTO, WEBP]
							aspectRatio: 1.64
						)
						description
						title
					}
					category {
						title
					}
					slug
					excerpt {
						excerpt
					}
				}
			}
		}
		recentArticles: allContentfulArticle(
			filter: { slug: { ne: $slug } }
			limit: 5
			sort: { fields: publishedDate, order: DESC }
		) {
			edges {
				node {
					title
					previewImage {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							aspectRatio: 1
							layout: FULL_WIDTH
							placeholder: NONE
						)
						description
						title
					}
					heroImage {
						gatsbyImageData(
							formats: [AUTO, WEBP]
							aspectRatio: 1
							layout: FULL_WIDTH
							placeholder: NONE
						)
						description
						title
					}
					category {
						title
					}
					slug
				}
			}
		}
	}
`;

export default ArticlePage;
