/**
 * Any changes to the numeric values of this config also need to made in `src/styles/abstracts/variables/_breakpoints.scss`
 * @return {object} Breakpoint config
 */
const BREAKPOINTS = {
	BASE: {
		LABEL: "base",
		VALUE: 1,
	},
	SMALL: {
		LABEL: "small",
		VALUE: 640,
	},
	MEDIUM: {
		LABEL: "medium",
		VALUE: 768,
	},
	LARGE: {
		LABEL: "large",
		VALUE: 1024,
	},
	XLARGE: {
		LABEL: "xlarge",
		VALUE: 1280,
	},
};

export default BREAKPOINTS;
