/**
 * Recent Article Cards populate on the right hand side of an article page, aka "more reads"
 * Cards can be of any category, ordered by date descending
 */
import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import "./styles.scss";
function ArticleCardSmall({ previewImage, heroImage, category, title, slug }) {
	const heroImageData = getImage(heroImage);
	const previewImageData = getImage(previewImage);
	return (
		<Link to={`/news/${slug}`} className="article-card-small">
			<div className="article-card-small__category">{category}</div>
			<div className="article-card-small__info">
				<div className="article-card-small__image">
					{previewImage ? (
						<GatsbyImage
							image={previewImageData}
							alt={
								previewImage.description
									? previewImage.description
									: previewImage.title
							}
						/>
					) : heroImage ? (
						<GatsbyImage
							image={heroImageData}
							alt={
								heroImage.description ? heroImage.description : heroImage.title
							}
						/>
					) : null}
				</div>
				<div className="text text--small text--weight-semi-bold">{title}</div>
			</div>
		</Link>
	);
}

export default ArticleCardSmall;

ArticleCardSmall.propTypes = {
	heroImage: PropTypes.shape({
		description: PropTypes.string,
		gatsbyImageData: PropTypes.object,
		title: PropTypes.string,
	}),
	tag: PropTypes.string,
	title: PropTypes.string,
};
